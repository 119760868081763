import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import paTopIcon from "../../assets/newImg/pa.png";
import { Button } from "react-bootstrap";
import "./Content.css";
import axios from "axios";

// const submitButton = async () => {
//   // e.preventDefault();
//   // setLoading(true);
//   try {
//     //https://eschool.appsosindia.org/views/api/payment_details_new.php?reg_code=HGSJ/1112/193
//     const result = await axios.get(
//       // `https://eschool.appsosindia.org/views/api/payment_details_new.php?reg_code=HGSJ/1112/193`
//       `https://eschool.appsosindia.org/views/api/homework.php`
//       //  `https://eschool.appsosindia.org/views/api/payment_details_new.php?reg_code=${form.reg_no}`
//       //  `https://eschool.appsosindia.org/views/accounts/php/fee_enquiry.php?reg_no=${form.reg_no}&mobile=${form.mobile}&academic_year=${form.academic_year}&school_code=HGSF`
//     );
//     console.log(result);

//   } catch (err) {
//     // setLoading(false);
//     console.log(err);
//   }
// };
// function fetch() {
//   return new Promise(resolve => setTimeout(() => resolve(42), 1000));
// }

// function fetchAPI(param) {
//   // param is a highlighted word from the user before it clicked the button
//   return fetch("https://eschool.appsosindia.org/views/api/homework.php");
// }
let bro = false;
function Students(props) {
  const [loading, setLoading] = useState(false);
  const [FData, setFData] = useState([]);

  async function callApi() {
    setLoading(true);
    try {
      //https://eschool.appsosindia.org/views/api/payment_details_new.php?reg_code=HGSJ/1112/193
      const result = await axios.get(
        // `https://eschool.appsosindia.org/views/api/payment_details_new.php?reg_code=HGSJ/1112/193`
        `https://cors-fix.viosa.in/https://eschool.appsosindia.org/views/api/homework.php`
        //  `https://eschool.appsosindia.org/views/api/payment_details_new.php?reg_code=${form.reg_no}`
        //  `https://eschool.appsosindia.org/views/accounts/php/fee_enquiry.php?reg_no=${form.reg_no}&mobile=${form.mobile}&academic_year=${form.academic_year}&school_code=HGSF`
      );
      // console.log(result.data.payment_info);
      setLoading(false);
      // bro=true;
      // setResult(result.data.payment_info?.length > 0 ? false : true);
      // console.log(result);
      bro = true;
      console.log(result.data.homework_info.length);
      console.log(result);

      setFData(result.data);

      // console.log(feesData, "feesdata");
      // console.log(feesData.TOTAL_BALANCE,"feesData.TOTAL_BALANCE");
      // if (feesData.TOTAL_BILL === 0) {
      //   bro = true;
      //   console.log(bro);
      // }else{
      //   bro=false;
      // }
      // console.log(feesData.length);
      // // if(!feesData.TOTAL_BILL){
      //   bro=true;

      // }
      // const datatbe=Array.from(feesData);
      // console(datatbe,"datate");
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
    // // alert('Button was pressed!');
    // fetch('https://cors-fix.viosa.in/https://eschool.appsosindia.org/views/api/homework.php', { method: 'GET' })
    //   .then(data => data.json()) // Parsing the data into a JavaScript object
    //   .then(json => alert(JSON.stringify(json))) // Displaying the stringified data in an alert popup
  }
  // let toggleButtonState = () => {
  //   alert('Button was pressed!');
  //   let selectedWord = "";
  //   fetchAPI(selectedWord).then(result => {
  //    console.log(result);
  //   });
  // };

  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Navbar isScrolled={isScrolled} handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div className="contentmain_cont">
        <div className="conatct-box">
          <Contact />
        </div>
        <div>
          {/* parents' voice */}
          <div className={`f-2 f-500 mb-3`} style={{ color: "#1FDC00ED" }}>
            <img
              src={paTopIcon}
              style={{ width: "4rem", marginRight: "1rem" }}
            />
            Student's Area
          </div>
          <div className="d-flex justify-content-between">
            <div>
              {/* <NavLink to="/student-tc"> */}
              <Button className="apply-btn cursor mx-2" onClick={callApi}>
                HOMEWORK
              </Button>
              {/* </NavLink> */}
            </div>
            <div>
              <NavLink to="/student-tc">
                <Button className="apply-btn cursor mx-2">Student TC</Button>
              </NavLink>
            </div>

            <div>
              <NavLink to="/new-registration">
                <Button className="apply-btn cursor mx-2">
                  New Registration
                </Button>
              </NavLink>
            </div>
            {/* <div>
              <NavLink to="/existing-fees">
                <Button className="apply-btn cursor mx-2">Fees Payment</Button>
              </NavLink>
            </div> */}
          </div>
          {bro && (
            <div>
              <h3>homework</h3>
              {FData.homework_info.length}
            </div>
          )}
          {/* 

          {/* students' voice */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Students;
