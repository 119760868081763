export const SUBMIT_HEADER = {
  STEP_8: {
    title: "Congratulation you have successfully registered",
    subTitle: "Thanks for registering with us",
  },
};

export const BUTTON_TEXT = {
  default: "Next",
  prev: "Previous",
  launch: "Go To Home Page",
};

export const STEPS = {
  STEP_1: "STEP_1",
  STEP_2: "STEP_2",
  STEP_3: "STEP_3",
  STEP_4: "STEP_4",
  STEP_5: "STEP_5",
  STEP_6: "STEP_6",
  STEP_7: "STEP_7",
  STEP_8: "STEP_8",
  SUBMIT: "SUBMIT",
};

const { SUBMIT, ...ALL_STEPS } = STEPS;
export const ALL_STEPS_LIST = Object.keys(ALL_STEPS);

export const NEXT_STEP = {
  STEP_1: "STEP_2",
  STEP_2: "STEP_3",
  STEP_3: "STEP_4",
  STEP_4: "STEP_5",
  STEP_5: "STEP_6",
  STEP_6: "STEP_7",
  STEP_7: "STEP_8",
  STEP_8: "SUBMIT",
};

export const PREV_STEP = {
  STEP_2: "STEP_1",
  STEP_3: "STEP_2",
  STEP_4: "STEP_3",
  STEP_5: "STEP_4",
  STEP_6: "STEP_5",
  STEP_7: "STEP_6",
  STEP_8: "STEP_7",
};

export const FORM = {
  STEP_1: [
    {
      name: "HGSB_PREG_DETAILS_ID",
      label: "HGSB_PREG_DETAILS_ID",
      placeholder: "HGSB PREG DETAILS ID",
    },
    {
      name: "ACADEMICYEAR_ID",
      label: "ACADEMICYEAR_ID",
      placeholder: " Academic Year Id",
    },
    {
      name: "REG_CODE",
      label: "REG_CODE",
      placeholder: "Registration Code",
    },
  ],
  STEP_2: [
    {
      name: "FNAME",
      label: "FNAME",
      placeholder: "Enter Student First Name",
    },
    {
      name: "LNAME",
      label: "lNAME",
      placeholder: "Enter Student Last Name",
    },
    {
      name: "FATHER_FNAME",
      label: "FATHER_FNAME",
      placeholder: "Enter Father First Name",
    },
    {
      name: "FATHER_LNAME",
      label: "FATHER_LNAME",
      placeholder: "Enter father last Name ",
    },
    {
      name: "MOTHER_FNAME",
      label: "MOTHER_FNAME",
      placeholder: "Enter Mother First Name ",
    },
    {
      name: "FT_EDU",
      label: "FT_EDU",
      placeholder: "Enter father Education",
    },
    {
      name: "MOTHER_LNAME",
      label: "MOTHER_LNAME",
      placeholder: "Enter mother last Name ",
    },
    {
      name: "DOB",
      label: "DOB",
      placeholder: "Enter Date of Birth",
    },
    {
      name: "GENDER",
      label: "GENDER",
      placeholder: "Enter Student Gender",
    },
    {
      name: "DOR",
      label: "DOR",
      placeholder: "Enter Date of Registration",
    },
    {
      name: "SSTATUS",
      label: "SSTATUS",
      placeholder: "Enter Student Status",
    },
  ],
  STEP_3: [
    {
      name: "ADDRESS1",
      label: "ADDRESS1",
      placeholder: "Address 1",
    },
    {
      name: "ADDRESS2",
      label: "ADDRESS2",
      placeholder: "Address 2",
    },
    {
      name: "COUNTRY",
      label: "COUNTRY",
      placeholder: "Country",
    },
    {
      name: "STATE",
      label: "STATE",
      placeholder: "State",
    },
    {
      name: "CITY",
      label: "CITY",
      placeholder: "City",
    },
    {
      name: "DISTRICT",
      label: "DISTRICT",
      placeholder: "District",
    },
    {
      name: "PIN",
      label: "PIN",
      placeholder: "Pin",
    },
    {
      name: "MOBILE",
      label: "MOBILE",
      placeholder: "Enter mobile number",
    },
    {
      name: "PHONE",
      label: "PHONE",
      placeholder: "Enter Phone number",
    },
    {
      name: "EMAIL_ID",
      label: "EMAIL_ID",
      placeholder: "Enter Email Id",
    },
    {
      name: "STD_TYPE",
      label: "STD_TYPE",
      placeholder: "Enter Student Type",
    },
  ],
  STEP_4: [
    {
      name: "ADMIN_CLASS",
      label: "ADMIN_CLASS",
      placeholder: "Enter Admin Class",
    },
    {
      name: "APP_STREAM",
      label: "APP_STREAM",
      placeholder: "Enter details  of how do you know ?",
    },
    {
      name: "PREMARK",
      label: "PREMARK",
      placeholder: "Enter class of the student",
    },
    {
      name: "PMODE",
      label: "PMODE",
      placeholder: "Enter birth certificate number",
    },
    {
      name: "PAMOUNT",
      label: "PAMOUNT",
      placeholder: "Enter payment amount",
    },
    {
      name: "CUSER",
      label: "CUSER",
      placeholder: "Enter created user",
    },
    {
      name: "CDATE",
      label: "CDATE",
      placeholder: "Enter created date",
    },
    {
      name: "EUSER",
      label: "EUSER",
      placeholder: "Enter edited user",
    },
    {
      name: "FLAG",
      label: "FLAG",
      placeholder: "Enter flag",
    },
    {
      name: "TFLAG",
      label: "TFLAG",
      placeholder: "Enter tflag",
    },
  ],
  STEP_5: [
    {
      name: "date",
      label: "date",
      placeholder: " Enter date",
    },
    {
      name: "PMODE",
      label: "PMODE",
      placeholder: "Enter payment mode",
    },
    {
      name: "TFEE",
      label: "TFEE",
      placeholder: "Enter total fee",
    },
    {
      name: "VEHICLE_DETAILS_ID",
      label: "VEHICLE_DETAILS_ID",
      placeholder: "Enter vehicle details id",
    },
    {
      name: "BUS_STOP",
      label: "BUS_STOP",
      placeholder: "Enter bus stop",
    },
    {
      name: "payment status ",
      label: "payment status ",
      placeholder: "Enter payment status ",
    },
  ],
  STEP_6: [
    {
      name: "PHOTO",
      label: "PHOTO",
      placeholder: "Enter photo",
    },
    {
      name: "STDCOST",
      label: "STDCOST",
      placeholder: "Enter student cost",
    },
    {
      name: "ADHAR_NO",
      label: "ADHAR_NO",
      placeholder: "Enter adhar number",
    },
    {
      name: "CATEGORY",
      label: "CATEGORY",
      placeholder: "Enter category",
    },
    {
      name: "RELIGION",
      label: "RELIGION",
      placeholder: "Enter religion",
    },
  ],
  STEP_7: [
    {
      name: "DOB_PLACE",
      label: "DOB_PLACE",
      placeholder: " Enter Date of Birth Place",
    },
    {
      name: "DOMICILE",
      label: "DOMICILE",
      placeholder: "Enter domicile",
    },
  ],
};
export const FORM1 = {
  STEP_1: [
    {
      name: "ADDRESS1",
      label: "ADDRESS1",
      placeholder: "Address 1",
    },
    {
      name: "ADDRESS2",
      label: "ADDRESS2",
      placeholder: "Address 2",
    },
    {
      name: "COUNTRY",
      label: "COUNTRY",
      placeholder: "Country",
    },
    {
      name: "STATE",
      label: "STATE",
      placeholder: "State",
    },
    {
      name: "CITY",
      label: "CITY",
      placeholder: "City",
    },
    {
      name: "DISTRICT",
      label: "DISTRICT",
      placeholder: "District",
    },
    {
      name: "PIN",
      label: "PIN",
      placeholder: "Pin",
    },
    {
      name: "Mint",
      label: "Mint",
      placeholder: "Mint",
    },
    {
      name: "Pint",
      label: "Pint",
      placeholder: "Pint",
    },
  ],
  STEP_2: [
    {
      name: "FT_FNAME",
      label: "FT_FNAME",
      placeholder: "Enter Father First Name",
    },
    {
      name: "FT_LNAME",
      label: "FT_LNAME",
      placeholder: "Enter father last Name ",
    },
    {
      name: "FT_OCUP",
      label: "FT_OCUP",
      placeholder: "Enter father Occupation",
    },
    {
      name: "FT_EDU",
      label: "FT_EDU",
      placeholder: "Enter father Education",
    },
    {
      name: "FT_OEDU",
      label: "FT_OEDU",
      placeholder: "Enter father Other Education",
    },
    {
      name: "FT_DESIG",
      label: "FT_DESIG",
      placeholder: "Enter father Designation",
    },
    {
      name: "FT_INCOME",
      label: "FT_INCOME",
      placeholder: "Enter father Income",
    },
    {
      name: "FT_OFFICE",
      label: "FT_OFFICE",
      placeholder: "Enter father Office",
    },
    {
      name: "FT_ADDRESS",
      label: "FT_ADDRESS",
      placeholder: "Enter father Address",
    },
    {
      name: "FT_CNO",
      label: "FT_CNO",
      placeholder: "Enter father Contact Number",
    },
    {
      name: "FT_MOBILE",
      label: "FT_MOBILE",
      placeholder: "Enter father Mobile Number",
    },
    {
      name: "FT_EMAIL",
      label: "FT_EMAIL",
      placeholder: "Enter father Email",
    },
  ],
  STEP_3: [
    {
      name: "MT_FNAME",
      label: "MT_FNAME",
      placeholder: "Enter mother First Name",
    },
    {
      name: "MT_LNAME",
      label: "MT_LNAME",
      placeholder: "Enter mother last Name ",
    },
    {
      name: "MT_OCUP",
      label: "MT_OCUP",
      placeholder: "Enter mother Occupation",
    },
    {
      name: "MT_EDU",
      label: "MT_EDU",
      placeholder: "Enter mother Education",
    },
    {
      name: "MT_OEDU",
      label: "MT_OEDU",
      placeholder: "Enter mother Other Education",
    },
    {
      name: "MT_DESIG",
      label: "MT_DESIG",
      placeholder: "Enter mother Designation",
    },
    {
      name: "MT_INCOME",
      label: "MT_INCOME",
      placeholder: "Enter mother Income",
    },
    {
      name: "MT_OFFICE",
      label: "MT_OFFICE",
      placeholder: "Enter mother Office",
    },
    {
      name: "MT_ADDRESS",
      label: "MT_ADDRESS",
      placeholder: "Enter mother Address",
    },
    {
      name: "MT_CNO",
      label: "MT_CNO",
      placeholder: "Enter mother Contact Number",
    },
    {
      name: "MT_MOBILE",
      label: "MT_MOBILE",
      placeholder: "Enter mother Mobile Number",
    },
    {
      name: "MT_EMAIL",
      label: "MT_EMAIL",
      placeholder: "Enter mother email",
    },
  ],
  STEP_4: [
    {
      name: "HKNOW",
      label: "HKNOW",
      placeholder: " How do you know ?",
    },
    {
      name: "HKNOW_DETAILS",
      label: "HKNOW_DETAILS",
      placeholder: "Enter details  of how do you know ?",
    },
    {
      name: "RPCLASS",
      label: "RPCLASS",
      placeholder: "Enter class of the student",
    },
    {
      name: "BCERTIFICATE",
      label: "BCERTIFICATE",
      placeholder: "Enter birth certificate number",
    },
    {
      name: "CCERTIFICATE",
      label: "CCERTIFICATE",
      placeholder: "Enter character certificate number",
    },
    {
      name: "SACERTIFICATE",
      label: "SACERTIFICATE",
      placeholder: "Enter school leaving certificate number",
    },
    {
      name: "APROOF",
      label: "APROOF",
      placeholder: "Enter address proof",
    },
    {
      name: "RPHOTO",
      label: "RPHOTO",
      placeholder: "Enter recent photo",
    },
    {
      name: "TC",
      label: "TC",
      placeholder: "Enter transfer certificate",
    },
    {
      name: "EWS",
      label: "EWS",
      placeholder: "Enter EWS certificate",
    },
  ],
  STEP_5: [
    {
      name: "parntimage",
      label: "parntimage",
      placeholder: " Enter parent image",
    },
    {
      name: "addrs",
      label: "addrs",
      placeholder: "Enter address proof",
    },
    {
      name: "category",
      label: "category",
      placeholder: "Enter category",
    },
    {
      name: "transfer",
      label: "transfer",
      placeholder: "Enter transfer",
    },
    {
      name: "dob",
      label: "dob",
      placeholder: "Enter date of birth",
    },
    {
      name: "report",
      label: "report",
      placeholder: "Enter report",
    },
  ],
  STEP_6: [
    {
      name: "SPORTS",
      label: "SPORTS",
      placeholder: "Enter sports",
    },
    {
      name: "DANCE",
      label: "DANCE",
      placeholder: "Enter dance",
    },
    {
      name: "SINING",
      label: "SINING",
      placeholder: "Enter singing",
    },
    {
      name: "ARTCRAFT",
      label: "ARTCRAFT",
      placeholder: "Enter artcraft",
    },
    {
      name: "SOTHER",
      label: "SOTHER",
      placeholder: "Enter other",
    },
  ],
  STEP_7: [
    {
      name: "PCLASS",
      label: "PCLASS",
      placeholder: " Enter previous class",
    },
    {
      name: "SNAME",
      label: "SNAME",
      placeholder: "Enter school name",
    },
    {
      name: "NBOARD",
      label: "NBOARD",
      placeholder: "Enter board name",
    },
    {
      name: "PYEAR",
      label: "PYEAR",
      placeholder: "Enter passing year",
    },
    {
      name: "MTYPE",
      label: "MTYPE",
      placeholder: "Enter medium type",
    },
    {
      name: "OMARK",
      label: "OMARK",
      placeholder: "Enter obtained marks",
    },
    {
      name: "GRADE",
      label: "GRADE",
      placeholder: "Enter grade",
    },
    {
      name: "SPORTS1",
      label: "SPORTS1",
      placeholder: "Enter sport 1",
    },
    {
      name: "SPORTS2",
      label: "SPORTS2",
      placeholder: "Enter sport 2",
    },
    {
      name: "ECACTIVITY1",
      label: "ECACTIVITY1",
      placeholder: "Enter extra curricular activity 1",
    },
    {
      name: "ECACTIVITY2",
      label: "ECACTIVITY2",
      placeholder: "Enter extra curricular activity 2",
    },
    {
      name: "RFLEAVING",
      label: "RFLEAVING",
      placeholder: "Enter reason for leaving",
    },
  ],
};
